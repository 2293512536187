import IOClient from './IOClient'

import sessionsProto from './protocols/sessions'
import userProfileProto from './protocols/userProfile'
import messagesProto from './protocols/messages'
import chatsProto from './protocols/chats'
import callsProto from './protocols/calls'
import favoriteProto from './protocols/favorite'
import acsProto from './protocols/acs'
import channelsProto from './protocols/channels'
import assistantProto from './protocols/assistant'
import radioProto from './protocols/radio'
import pollProto from './protocols/poll'
import locationProto from './protocols/location'
import botsProto from './protocols/bots'
import webrtcProto from './protocols/webrtc'
import conferenceProto from './protocols/conference'
import contactsProto from './protocols/contacts'
import encryptionProto from './protocols/encryption'
import dlpProto from './protocols/dlp'
import rolesProto from './protocols/roles'
import scheduleProto from './protocols/schedule'


import meetingsProto from './protocols/meetings'

import axios from 'axios'

class CommonIOClient extends IOClient {
    constructor () {
        super({logName: 'CommonIOClient'})
        this._subscribeOnSocket()
    }

    async getCompanies() {
        try {
            return []
        } catch (e) {
            console.log(e)
            return []
        }
    }

    _subscribeOnSocket() {
        this.addSocketEmitters(encryptionProto.emitters)
        this.on('connect', this._onSocketConnected.bind(this))
        this.on('disconnect')
        this.on('connect_error')
    }

    _onSocketConnected(socket) {
        const date = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
        const name = 'io'
        const value = socket.io.engine.id
        const expires = date.toUTCString()

        const urlObj = new URL(socket.io.uri)
        const url = urlObj.protocol + '//' + urlObj.hostname
        if (window.rpc) window.rpc.setCookie({ url, name, value, expires })
        {
            [
                sessionsProto,
                userProfileProto,
                messagesProto,
                chatsProto,
                callsProto,
                favoriteProto,
                acsProto,
                channelsProto,
                channelsProto,
                assistantProto,
                radioProto,
                pollProto,
                locationProto,
                botsProto,
                webrtcProto,
                meetingsProto,
                conferenceProto,
                contactsProto,
                dlpProto,
                rolesProto,
                scheduleProto
            ].forEach((proto) => {
                this.addSocketEmitters(proto.emitters)
                this.addSocketListeners(proto.listeners)
            })
        }
    }

    _onSocketDisconnected() {

    }
}

export default CommonIOClient