//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict"
import ProgressCircle from "../ProgressCircle.vue"
import ContentLoader from "../main/body/MainContentLoader.vue"
import ChatMessageMediaViewer from "../mediaViewer/ChatMessageMediaViewer.vue"
import ChatMessageMixin from "./chat-message-mixin"
import { mapGetters } from 'vuex'
import { CONTACTS } from '../../store/modulesNames'
import { GET_MERGED_CONTACT_BY_ID } from '../../store/gettersTypes'

const gifMaxSize = 5000000

export default {
    name: "chat-image-message",
    props: ["message"],
    components: {
        "progress-circle": ProgressCircle,
        ContentLoader,
    },
    mixins: [ChatMessageMixin],
    asyncComputed: {
        src() {
            const remoteHost = this.message.remoteServerHost || ''
            if (this.link) {
                return this.link
            }
            else {
                let { extension, size } = this.msg
                if (extension === 'gif' && size <= gifMaxSize) {
                    return this.getGifPreview(this.msg, remoteHost)
                }
                return this.getPreview(this.msg, remoteHost)
            }
        },
    },
    computed: {
        id() { 
            return this.message.id 
        },
        msg() { return this.message.msg },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        setStyle() {
            return `background-image: url(${this.src})`
        },
        doShowViewer() {
            this.$modal.show(
                ChatMessageMediaViewer, {
                    src: this.msg,
                    message: this.message,
                    type: "image"
                }, {
                    name: "viewer",
                    delay: 200,
                    width: "100%",
                    height: "100%",
                    classes: ["v--modal", "v--modal-box", "no-gap-on-top"]
                })
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
    }
};
