
import { mapActions, mapGetters } from 'vuex'
import {USERDATA, VIDEO_CONF_NEW, VM_CONF} from "../../store/modulesNames"
import {GET_MEDIA_DEVICES, GET_APP_REDIRECTED_SERVER} from '../../store/gettersTypes'
import {
    ACT_VC_CONF_JOIN,
    ACT_VC_CONF_JOIN_BY_LINK,
    ACT_VM_CONF_UPDATE_DEVICES,
} from "../../store/actionsTypes"

import videoConfBase from './videoConfBase.vue'
import videomostMembersVideo from "./videomostMembersVideo.vue"

import { CONF_TYPES } from "../../constants"

import APP_BUILD_ENV from '../../buildEnv'

export default {
    name: "RoschatConf",
    extends: videoConfBase,
    props: {
        userName: {
            type: String,
            required: false,
            default: `${APP_BUILD_ENV.APP_NAME}Desktop`
        },
        link: {
            type: String,
            required: false,
        },
        server: {
            type: String,
            required: false,
            default: ''
        },
        confId: {
            type: String,
            required: false,
        },
        pass: {
            type: String,
            required: false,
        },
        noCamera: {
            type: Boolean,
            required: false,
            default: false
        },
        noMicrophone: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            id: 0,
            type: CONF_TYPES.ROSCHAT,
            videoComp: videomostMembersVideo,
            videoCompReadyReady: false,
        }
    },
    watch: {
        devicesCount() {
            this[ACT_VM_CONF_UPDATE_DEVICES]({id: this.id})
        },
    },
    computed: {
        devicesCount() {
            return this[GET_MEDIA_DEVICES].length
        },
        ourHost() {
            let ourUrl = this[GET_APP_REDIRECTED_SERVER]
            return ourUrl.hostname
        },
        ...mapGetters(USERDATA, [GET_MEDIA_DEVICES, GET_APP_REDIRECTED_SERVER]),
    },
    methods: {
        async onVideoCompReady(videoComp) {
            this.videoCompReadyReady = true
            this.connect(videoComp)
        },
        async connect(videoComp) {
            //if (!(this.videoCompReadyReady && (this.link))) return
            console.log('~~~~~~~~~~~~~connect')
            let remote = videoComp.$refs.remote
            let share = videoComp.$refs.share

            let props = {
                type: this.type,
                el: remote,
                share,
                username: this.userName,
                noCamera: this.noCamera,
                noMicrophone: this.noMicrophone,
            }

            console.log('~~~~~~~~~~~~~props ' + JSON.stringify(props))

            if (this.link) {
                this.id = await this[ACT_VC_CONF_JOIN_BY_LINK]({ ...props, ...{ link: this.link } })
            } else {
                this.id = await this[ACT_VC_CONF_JOIN]({ ...props, ...{ server: this.server || this.ourHost, confId: this.confId, pass: this.pass }})
            }
        },
        ...mapActions(VM_CONF, [
            ACT_VM_CONF_UPDATE_DEVICES,
        ]),
        ...mapActions(VIDEO_CONF_NEW, [
            ACT_VC_CONF_JOIN,
            ACT_VC_CONF_JOIN_BY_LINK,
        ])
    },

}
