import module, {
    MEMBER_PROPS as MEMBER_PROPS_BASE,
    CONF_PROPS,
    CONF_SCHEDULE_PERIODICITY_DAYS,
    CONF_SCHEDULE_PERIODICITY_PROPS,
    CONF_SCHEDULE_PERIODICITY_TYPES,
    CONF_SCHEDULE_PROPS, END_REASONS,
    INFO_PROPS,
    STATUS,
} from './video-conf-abstract?t=roschat'
import {CONF_TYPES} from '../../constants'

import MeetingsTransportWrapper from '../../sdk/roschat/MeetingsTransportWrapper'
import MeetingsClient, {
    CLIENT_EVENTS,
    PARTICIPANT_PROPS,
    PARTICIPANT_ROLES,
    CONFERENCE_STATES,
    CONF_PROPS as CLIENT_CONF_PROPS,
} from '../../sdk/roschat/MeetingsClient'

import {
    ACT_CONF_ANSWER_CID,
    ACT_CONF_BAN_MEMBER,
    ACT_CONF_CAMERA_TOGGLE,
    ACT_CONF_CREATE,
    ACT_CONF_DELETE,
    ACT_CONF_FINISH,
    ACT_CONF_HAND_UP,
    ACT_CONF_JOIN,
    ACT_CONF_JOIN_BY_LINK,
    ACT_CONF_KICK_OUT_MEMBER,
    ACT_CONF_LEAVE,
    ACT_CONF_MICROPHONE_TOGGLE,
    ACT_CONF_MINIMIZE,
    ACT_CONF_REACTION,
    ACT_CONF_SEND_CID,
    ACT_CONF_SEND_INVITE,
    ACT_CONF_SET_DEVICE,
    ACT_CONF_SET_MEMBER_MIC,
    ACT_CONF_SET_MEMBER_MODERATOR,
    ACT_CONF_SET_MEMBER_VIDEO,
    ACT_CONF_SHARE,
    ACT_CONF_SPEAKER_TOGGLE,
    ACT_CONF_WANT_SPEAK,
    ACT_GET_CONF_LIST,
    ACT_ROSCHAT_CONF_API_MEETING_START,
    ACT_ROSCHAT_CONF_API_MEETING_STOP,
    ACT_ROSCHAT_CONF_API_MEETING_INFO,
    ACT_ROSCHAT_CONF_API_MEETING_ENTER,
    ACT_ROSCHAT_CONF_API_MEETING_EXIT,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_OFFER,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_ADD_CANDIDATE,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_REMOVE_CANDIDATE,
    ACT_ROSCHAT_CONF_API_CHANGE_MEETING_USER,
    ACT_ROSCHAT_CONF_API_DELETE_MEETING_USER,
    ACT_ROSCHAT_CONF_API_MEETING_INVITE_USERS,
    ACT_ROSCHAT_CONF_API_MEETING_REJECT_USER,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_COMMAND,
    ACT_ROSCHAT_CONF_API_CREATE_TRANSPORT,
    ACT_ROSCHAT_CONF_API_DESTROY_TRANSPORT,
    ACT_ROSCHAT_CONF_HANDLE_API_MEETING_EVENT,
    ACT_USERDATA_GET_TURN_SERVER,
    ACT_CONF_SET_DEVICES,
    ACT_ROSCHAT_CONF_API_SHARING_START,
    ACT_ROSCHAT_CONF_API_SHARING_STOP,
    ACT_CONF_CHANGE_MEMBER_PROPS,
    ACT_CHECK_MEDIA_DEVICES,
    ACT_ROSCHAT_CONF_HANDLE_ERROR_MEETING_EVENT,
} from '../actionsTypes'
import {CONTACTS, USERDATA} from '../modulesNames'
import {
    GENERATE_CONF_PROPS,
    GET_ACTIVE_MEDIA_DEVICES,
    GET_CONF_INFO,
    GET_CONF_INVITE_TEXT,
    GET_CONF_OWN_MEMBER,
    GET_MEDIA_DEVICES,
    GET_MERGED_CONTACT_BY_ID,
    GET_APP_REDIRECTED_SERVER,
    GET_UID,
    GET_USER_PARAMS,
    IS_ROSCHAT_CONF_ENABLED,
    GET_CONF_ERROR_TEXT,
} from '../gettersTypes'
import {
    CONF_SETTINGS_GLOBAL_PROPS,
    CONF_SETTINGS_INFO_PROPS,
    CONF_SETTINGS_PROPS,
    EVENTS,
    MEMBER_ATTRS_PROPS,
    MEMBER_PROPS,
    MEMBER_STATES_PROPS,
} from '../../sdk/videomost/VideomostClient'
import {
    MUT_CONF_ADD,
    MUT_CONF_ADD_MEMBER, MUT_CONF_REMOVE,
    MUT_CONF_REMOVE_MEMBER,
    MUT_CONF_UPDATE_MEMBER,
    MUT_SET_CONF_SHARE,
    MUT_UPDATE_CONF,
} from '../mutationsTypes'

export const ROSCHAT_CONF_SCHEDULE_PROPS = {
    MEETING_ID: 'meetingId',
    RUNNING: 'running',
    TOPIC: 'topic',
    AUTHOR: 'author',
    START_TIME: 'startTime',
    DURATION: 'duration',
    REPEAT: 'repeat',
    PASSWORD: 'password',
    SETTINGS: 'settings',
}

export const ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS = {
    TYPE: 'type',
    NUM: 'num',
    DAYS: 'days',
}

export const ROSCHAT_CONF_SCHEDULE_PERIODICITY_TYPES = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
}

export const ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS = {
    MN: 'Mn',
    TU: 'Tu',
    WE: 'We',
    TH: 'Th',
    FR: 'Fr',
    SA: 'Sa',
    SU: 'Su',
}

export const ROSCHAT_CONF_SETTINGS_PROPS = {
    VIDEO: 'video',
    WAITING: 'waiting',
    AT_ONCE: 'atOnce',
}

const COMMANDS = {
    MESSAGE: 'message',
    HAND_UP: 'hand-up',
    EMOTION: 'emotion'
}

const LINK_REG = /\w+:\/\/(?<server>[^\/]+)\/join\/\?meetingid=(?<confId>[^\&]+)&password=(?<pass>[^($|&)]+)/gm

let transportWrapper = null
let meetingClient = null

const state = {

}

const getters = {
    [GENERATE_CONF_PROPS]: (state, getters, rootState, rootGetters) => ({confId, pass, server, link}) => {
        const ourUrl = new URL(rootGetters[`${USERDATA}/${GET_APP_REDIRECTED_SERVER}`])
        if (link) {
            let temp
            let groups = null
            while ((temp = LINK_REG.exec(link)) !== null) groups = temp.groups
            let { confId: _confId, server: _server, pass: _pass } = groups || {}
            server = _server
            confId = _confId
            pass = _pass
        } else {
            if (!server) server = ourUrl.hostname
        }
        let generatedLink = `https://${ourUrl.hostname}/join/?meetingid=${confId}&password=${pass}`

        return { id: confId, confId: confId, server, pass, link, generatedLink }
    },
    [IS_ROSCHAT_CONF_ENABLED]: (state, getters, rootState, rootGetters) => {
        let ud = rootGetters[`${USERDATA}/${GET_USER_PARAMS}`]
        return Boolean((ud.services || []).find(({type}) => type === 'conference'))
    },
    [GET_CONF_ERROR_TEXT]: () => (error) => {
        let errorText = locale.errors.unknown
        switch (error) {
            case XML_RPC_ERRORS.SESSION_EXPIRED:
                errorText = locale.videomost.errors['conf-session-expired']
                break
            case XML_RPC_ERRORS.ACCESS:
                errorText = locale.videomost.errors['conf-access']
                break
            case XML_RPC_ERRORS.CONFERENCE_FORMAT_DATE:
                errorText = locale.videomost.errors['conf-format-date']
                break
            case XML_RPC_ERRORS.CONFERENCE_EXIST:
                errorText = locale.videomost.errors['conf-exist']
                break
            case XML_RPC_ERRORS.DB:
                errorText = locale.videomost.errors['conf-db']
                break
            case XML_RPC_ERRORS.CONFERENCE_NOT_EXIST:
                errorText = locale.videomost.errors['conf-not-exist']
                break
            case XML_RPC_ERRORS.CONFERENCE_WRONG_PARAM:
                errorText = locale.videomost.errors['conf-wrong-param']
                break
            case XML_RPC_ERRORS.CONFERENCE_PARAM_NOTALLOW:
                errorText = locale.videomost.errors['conf-param-not-allow']
                break

        }
        return errorText
    },
}

const actions = {
    async [ACT_ROSCHAT_CONF_API_CREATE_TRANSPORT](obj, {}){},
    async [ACT_ROSCHAT_CONF_API_DESTROY_TRANSPORT](obj, {}){},

    async [ACT_ROSCHAT_CONF_API_MEETING_START](obj, {}){},
    async [ACT_ROSCHAT_CONF_API_MEETING_START](){},
    async [ACT_ROSCHAT_CONF_API_MEETING_STOP](){},
    async [ACT_ROSCHAT_CONF_API_MEETING_INFO](){},
    async [ACT_ROSCHAT_CONF_API_MEETING_ENTER](){},
    async [ACT_ROSCHAT_CONF_API_MEETING_EXIT](){},
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_OFFER](){},
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_ADD_CANDIDATE](){},
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_REMOVE_CANDIDATE](){},
    async [ACT_ROSCHAT_CONF_API_CHANGE_MEETING_USER](){},
    async [ACT_ROSCHAT_CONF_API_DELETE_MEETING_USER](){},
    async [ACT_ROSCHAT_CONF_API_MEETING_INVITE_USERS](){},
    async [ACT_ROSCHAT_CONF_API_MEETING_REJECT_USER](){},
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_COMMAND](){},
    async [ACT_ROSCHAT_CONF_API_SHARING_START](){},
    async [ACT_ROSCHAT_CONF_API_SHARING_STOP](){},

    async [ACT_ROSCHAT_CONF_HANDLE_API_MEETING_EVENT](obj, payload){
        transportWrapper && transportWrapper.handleAPIEvent(payload)
    },
    async [ACT_ROSCHAT_CONF_HANDLE_ERROR_MEETING_EVENT]({commit}, e){
        if (!meetingClient) return
        commit(MUT_UPDATE_CONF, { id: meetingClient.meetingId, props: { [CONF_PROPS.STATUS]: STATUS.ERROR, [CONF_PROPS.ERROR_TEXT]: e.message } })
        meetingClient.leave()
    },
    async [ACT_CONF_JOIN](obj, { el, share, server, confId, pass, noCamera = true, noMicrophone = true, username = '', ...payload}) {
        let {commit, dispatch, rootGetters} = obj
        try {
            await dispatch(`${USERDATA}/${ACT_CHECK_MEDIA_DEVICES}`, {
                audio: true,
                video: true,
                audioAlert: false,
                videoAlert: false
            }, {root: true})
        } catch (e) {}
        commit(MUT_CONF_ADD, { id: confId, props: { [CONF_PROPS.STATUS]: STATUS.CONNECTING }})
        try {
            await dispatch(ACT_ROSCHAT_CONF_API_CREATE_TRANSPORT, {server})
        } catch (e) {
            commit(MUT_UPDATE_CONF, { id: confId, props: { [CONF_PROPS.STATUS]: STATUS.ERROR, [CONF_PROPS.ERROR_TEXT]: 'Transport connect error' } })
        }
        transportWrapper = new MeetingsTransportWrapper({store: this})
        let activeDevices = rootGetters[`${USERDATA}/${GET_ACTIVE_MEDIA_DEVICES}`]
        meetingClient = new MeetingsClient({
            el,
            transport: transportWrapper,
            meetingId: confId,
            name: username,
            password: pass,
            activeDevices
        })
        window.meetingClientTest = meetingClient
        dispatch(ACT_CONF_SET_DEVICES, { id: confId, devices: activeDevices })
        subscribeOnClient(obj, meetingClient)
        meetingClient.join({el, share, mic: !noMicrophone, cam: !noCamera}).then(() => {
            let settings = meetingClient.getSettings()
            let info = prepareRoschatInfo({ confId, server, password: pass, ...settings })
            commit(MUT_UPDATE_CONF, { id: confId, props: { [CONF_PROPS.INFO]: info } })
        }).catch((e) => {
            console.log('~~~~~~~~~~~~~~~~joinConference error', e)
            commit(MUT_UPDATE_CONF, { id: confId, props: { [CONF_PROPS.STATUS]: STATUS.ERROR, [CONF_PROPS.ERROR_TEXT]: e.message } })
            meetingClient.leave()
        })
        return confId
    },
    async [ACT_CONF_JOIN_BY_LINK]({dispatch, commit}, {link, ...payload}) {
        console.log('~~~roschat ACT_CONF_JOIN_BY_LINK')
        let temp
        let groups = null
        while ((temp = LINK_REG.exec(link)) !== null) groups = temp.groups
        let { confId, pass, server } = groups || {}
        return dispatch(ACT_CONF_JOIN, { confId, pass, server, ...payload })
    },
    async [ACT_CONF_FINISH]({dispatch, commit}, {id}) {
        dispatch(ACT_ROSCHAT_CONF_API_MEETING_STOP, {meetingId: id})
    },
    async [ACT_CONF_LEAVE]({commit,dispatch}, {id}) {
        if (meetingClient) {
            meetingClient.leave()
            meetingClient = null
            dispatch(ACT_ROSCHAT_CONF_API_DESTROY_TRANSPORT)
            commit(MUT_CONF_REMOVE, {id})
        }
    },
    async [ACT_CONF_MINIMIZE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_CAMERA_TOGGLE]() {
        if (!meetingClient) return
        meetingClient.toggleCamera()
    },
    async [ACT_CONF_MICROPHONE_TOGGLE]() {
        if (!meetingClient) return
        meetingClient.toggleMicrophone()
    },
    async [ACT_CONF_SPEAKER_TOGGLE]({commit}) {
        if (!meetingClient) return
        let muted = meetingClient.toggleSpeaker()
        commit(MUT_UPDATE_CONF, {id: meetingClient.meetingId, props: {[CONF_PROPS.SPEAKER_STATUS]: !muted}}) //@todo meetingId
    },
    [ACT_CONF_CHANGE_MEMBER_PROPS]({getters}, {id, memberId, prop, value}) {
        let client = meetingClient
        if (client) {
            let props = { }
            switch (prop) {
                case MEMBER_PROPS_BASE.MODERATOR:
                    props[PARTICIPANT_PROPS.ROLE] = value ? PARTICIPANT_ROLES.MODERATOR : PARTICIPANT_ROLES.USER
                    break
                case MEMBER_PROPS_BASE.CAMERA_GLOBAL:
                    props[PARTICIPANT_PROPS.CAM] = value
                    break
                case MEMBER_PROPS_BASE.MICROPHONE_GLOBAL:
                    props[PARTICIPANT_PROPS.MIC] = value
                    break
                case MEMBER_PROPS_BASE.BANNED:
                    let time = 0
                    if (value === 2) {
                        time = Math.floor((new Date).getTime() / 1000) + 365 * 24 * 60 * 60
                    } else if (value) {
                        time = Math.floor((new Date).getTime() / 1000) + + 10
                    }
                    props[PARTICIPANT_PROPS.BANNED] = time
                    break
                case MEMBER_PROPS_BASE.WANT_SPEAK:
                    props[PARTICIPANT_PROPS.LET_ME_SPEAK] = value
                    break
            }
            client.changeParticipantProps({meetingId: id, id: memberId, props })
        }
    },
    async [ACT_CONF_SHARE]() {
        if (!meetingClient) return
        meetingClient.shareToggle()
    },
    async [ACT_CONF_SEND_CID]() {

    },
    async [ACT_CONF_ANSWER_CID]() {

    },
    [ACT_CONF_HAND_UP](obj, { id, handup }) {
        if (!meetingClient) return
        meetingClient.sendCommand({cmd: COMMANDS.HAND_UP, data: handup})
    },
    [ACT_CONF_REACTION](obj, { id, reaction }) {
        if (!meetingClient) return
        meetingClient.sendCommand({cmd: COMMANDS.EMOTION, data: reaction})
    },
    async [ACT_CONF_SET_DEVICE](obj, {id, kind, deviceId}) {
        if (!meetingClient) return
        meetingClient.changeMediaDevice(kind, deviceId)
    },
}

const mutations = {
}

function subscribeOnClient({ commit, dispatch, getters }, client) {
    let id = client.getActiveConfId()
    client.on(EVENTS.CONF_TERMINATED, (reason) => {
        if (!(reason in END_REASONS)) reason = END_REASONS.UNKNOWN
        commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.END_REASON]: reason, [CONF_PROPS.STATUS]: STATUS.ENDED } })
    })
    client.on(CLIENT_EVENTS.CONF_STATE_CHANGED, ({state, reason}) => {
        switch (state) {
            case CONFERENCE_STATES.ONLINE:
                commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.STATUS]: STATUS.ACTIVE } })
                break
            case CONFERENCE_STATES.WAITING:
                commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.STATUS]: STATUS.WAITING } })
                break
            case CONFERENCE_STATES.OFFLINE:
                commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.END_REASON]: reason, [CONF_PROPS.STATUS]: STATUS.ENDED } })
                break
        }

    })
    client.on(CLIENT_EVENTS.CONF_PARTICIPANT_ADDED, (user) => {
        let member = prepareRoschatMember(user)
        commit(MUT_CONF_ADD_MEMBER, { id, member})
        //dispatch(ACT_CONF_SEND_CID, { id, to: member[MEMBER_PROPS_BASE.ID] })
    })
    client.on(CLIENT_EVENTS.CONF_PARTICIPANT_UPDATED, (user, attr, newVal) => {
        let member = prepareRoschatMember(user)
        commit(MUT_CONF_UPDATE_MEMBER, { id, member})
    })
    client.on(CLIENT_EVENTS.CONF_PARTICIPANT_REMOVED, (user, attr, newVal) => {
        let member = prepareRoschatMember(user)
        // если нам включили камеру или микрофон, то снимаем WANT_SPEAK
        if (member[MEMBER_PROPS_BASE.ME]) {
            const oldVal = getters[GET_CONF_OWN_MEMBER](id)
            if (oldVal[MEMBER_PROPS_BASE.WANT_SPEAK]) {
                if (member[MEMBER_PROPS_BASE.CAMERA_GLOBAL] || member[MEMBER_PROPS_BASE.MICROPHONE_GLOBAL]) {
                    dispatch(ACT_CONF_WANT_SPEAK, {id, value: false})
                }
            }
        }
        commit(MUT_CONF_UPDATE_MEMBER, { id, member})
    })
    client.on(CLIENT_EVENTS.CONF_PARTICIPANT_REMOVED, (user) => {
        commit(MUT_CONF_REMOVE_MEMBER, {id, member: prepareRoschatMember(user)})
    })
    client.on(CLIENT_EVENTS.SHARE_STARTED, ({userId}) => {
        commit(MUT_SET_CONF_SHARE, {id, userId})
    })
    client.on(CLIENT_EVENTS.SHARE_ENDED, () => {
        commit(MUT_SET_CONF_SHARE, {id})
    })
    client.on(CLIENT_EVENTS.CONF_DEVICES_CHANGED, ( devices ) => {
        console.log('!!!!!!###################CLIENT_EVENTS.CONF_DEVICES_CHANGED')
        dispatch(ACT_CONF_SET_DEVICES, { id, devices })
    })
    client.on(CLIENT_EVENTS.CONF_COMMAND, ( {meetingId, id: pId, cmd, data} ) => {
        if (meetingId !== id) return
        let member = { [MEMBER_PROPS_BASE.ID]: pId }
        switch (cmd) {
            case COMMANDS.MESSAGE:
                break
            case COMMANDS.HAND_UP:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.HANDUP]: data }})
                break
            case COMMANDS.EMOTION:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.REACTION]: data }})
                break
        }
    })
}

function prepareRoschatMember(user) {
    console.log('~~~~~~~~~prepareRoschatMember', user)
    return {
        [MEMBER_PROPS_BASE.ID]: user[PARTICIPANT_PROPS.ID],
        [MEMBER_PROPS_BASE.NAME]: user[PARTICIPANT_PROPS.NAME],
        [MEMBER_PROPS_BASE.ME]: user[PARTICIPANT_PROPS.IS_ME],
        [MEMBER_PROPS_BASE.BANNED]: user[PARTICIPANT_PROPS.BANNED], //@todo это время
        [MEMBER_PROPS_BASE.MODERATOR]: user[PARTICIPANT_PROPS.MODERATOR],
        //[MEMBER_PROPS_BASE.HIDDEN]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.HIDDEN]),
        [MEMBER_PROPS_BASE.OWNER]: Boolean(user[PARTICIPANT_PROPS.OWNER]),
        //[MEMBER_PROPS_BASE.PRESENTER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.PRESENTER]),
        //[MEMBER_PROPS_BASE.PRIORITY]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.PRIORITY]),
        //[MEMBER_PROPS_BASE.READER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.READER]),
        [MEMBER_PROPS_BASE.CAMERA_GLOBAL]: Boolean(user[PARTICIPANT_PROPS.CAN_CAM]),
        [MEMBER_PROPS_BASE.CAMERA]: Boolean(user[PARTICIPANT_PROPS.CAM]),
        [MEMBER_PROPS_BASE.MICROPHONE_GLOBAL]: Boolean(user[PARTICIPANT_PROPS.CAN_MIC]),
        [MEMBER_PROPS_BASE.MICROPHONE]: Boolean(user[PARTICIPANT_PROPS.MIC]),
        [MEMBER_PROPS_BASE.WANT_SPEAK]: Boolean(user[PARTICIPANT_PROPS.LET_ME_SPEAK]),
        //[MEMBER_PROPS_BASE.WANT_SHARE]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WANTSHARE]),
        //[MEMBER_PROPS_BASE.WANT_SPEAK]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WANTSPEAK]),
        //[MEMBER_PROPS_BASE.WRITER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WRITER]),
    }
}

function prepareRoschatInfo(confSettings) {

    let server = confSettings.server //@todo
    let link = `https://${server}/join/?meetingid=${confSettings.confId}&password=${confSettings.password}`  //@todo

    return {
        [INFO_PROPS.TOPIC]: confSettings[CLIENT_CONF_PROPS.TOPIC],
        [INFO_PROPS.OWNER_CID]: null,
        [INFO_PROPS.OWNER_NAME]: confSettings[CLIENT_CONF_PROPS.AUTHOR],
        [INFO_PROPS.CONF_ID]: confSettings.confId,
        [INFO_PROPS.PASSWORD]: confSettings.password,  //@todo
        [INFO_PROPS.LINK]: link,
        [INFO_PROPS.START_TIME]: confSettings[CLIENT_CONF_PROPS.START_TIME],
        [INFO_PROPS.FINISH_TIME]: confSettings[CLIENT_CONF_PROPS.START_TIME] + confSettings[CLIENT_CONF_PROPS.DURATION],
    }
}

export function convertRoschatConfToCommonFormat(conf) {
    let commonVmPropsKeys = [
        ROSCHAT_CONF_SCHEDULE_PROPS.MEETING_ID,
        ROSCHAT_CONF_SCHEDULE_PROPS.RUNNING,
        ROSCHAT_CONF_SCHEDULE_PROPS.START_TIME,
        ROSCHAT_CONF_SCHEDULE_PROPS.DURATION,
        ROSCHAT_CONF_SCHEDULE_PROPS.TOPIC,
        ROSCHAT_CONF_SCHEDULE_PROPS.PASSWORD,
        ROSCHAT_CONF_SCHEDULE_PROPS.REPEAT,
        ROSCHAT_CONF_SCHEDULE_PROPS.SETTINGS,
    ]
    let commonVmProps = commonVmPropsKeys.reduce((prev, cur) => {
        prev[cur] = conf[cur]
        delete conf[cur]
        return prev
    }, {})
    let commonConf = { [CONF_SCHEDULE_PROPS.TYPE]: CONF_TYPES.ROSCHAT }
    commonConf[CONF_SCHEDULE_PROPS.ID] = commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.MEETING_ID]
    commonConf[CONF_SCHEDULE_PROPS.START_TIME] = commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.START_TIME]
    if (commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.DURATION]) {
        commonConf[CONF_SCHEDULE_PROPS.END_TIME] =
            commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.START_TIME] + commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.DURATION]
    }
    commonConf[CONF_SCHEDULE_PROPS.TOPIC] = commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.TOPIC]
    commonConf[CONF_SCHEDULE_PROPS.PASSWORD] = commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.PASSWORD]
    const settings = commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.SETTINGS] || {}
    commonConf[CONF_SCHEDULE_PROPS.VIDEO_DISABLED] = !settings[ROSCHAT_CONF_SETTINGS_PROPS.VIDEO]
    commonConf[CONF_SCHEDULE_PROPS.WAITING] = !!settings[ROSCHAT_CONF_SETTINGS_PROPS.WAITING]
    commonConf[CONF_SCHEDULE_PROPS.AT_ONCE] = !!settings[ROSCHAT_CONF_SETTINGS_PROPS.AT_ONCE]
    const periodicity = commonVmProps[ROSCHAT_CONF_SCHEDULE_PROPS.REPEAT]
    if (periodicity && periodicity[ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]) {
        let type
        let commonDays = []
        switch (periodicity[ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]) {
            case ROSCHAT_CONF_SCHEDULE_PERIODICITY_TYPES.DAY:
                type = CONF_SCHEDULE_PERIODICITY_TYPES.DAY
                break
            case ROSCHAT_CONF_SCHEDULE_PERIODICITY_TYPES.WEEK:
                type = CONF_SCHEDULE_PERIODICITY_TYPES.WEEK
                let days = periodicity[ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS.DAYS]
                if (!days) break
                if (days.includes(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.MN)) commonDays.push(CONF_SCHEDULE_PERIODICITY_DAYS.MN)
                if (days.includes(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.TU)) commonDays.push(CONF_SCHEDULE_PERIODICITY_DAYS.TU)
                if (days.includes(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.WE)) commonDays.push(CONF_SCHEDULE_PERIODICITY_DAYS.WE)
                if (days.includes(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.TH)) commonDays.push(CONF_SCHEDULE_PERIODICITY_DAYS.TH)
                if (days.includes(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.FR)) commonDays.push(CONF_SCHEDULE_PERIODICITY_DAYS.FR)
                if (days.includes(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.SA)) commonDays.push(CONF_SCHEDULE_PERIODICITY_DAYS.SA)
                if (days.includes(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.SU)) commonDays.push(CONF_SCHEDULE_PERIODICITY_DAYS.SU)
                break
            case ROSCHAT_CONF_SCHEDULE_PERIODICITY_TYPES.MONTH:
                type = CONF_SCHEDULE_PERIODICITY_TYPES.MONTH
                break
        }
        if (type) {
            commonConf[CONF_SCHEDULE_PROPS.PERIODICITY] = {
                [CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]: type,
                [CONF_SCHEDULE_PERIODICITY_PROPS.COUNT]: periodicity[ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS.NUM],
            }
            if (commonDays.length) commonConf[CONF_SCHEDULE_PROPS.PERIODICITY][CONF_SCHEDULE_PERIODICITY_PROPS.DAYS] = commonDays
        }
    }

    return { additionalProps: conf, ...commonConf }
}

export function convertCommonConfToRochatFormat(conf) {
    let roschatConf = { ...conf.additionalProps }
    roschatConf[ROSCHAT_CONF_SCHEDULE_PROPS.MEETING_ID] = conf[CONF_SCHEDULE_PROPS.ID]
    roschatConf[ROSCHAT_CONF_SCHEDULE_PROPS.START_TIME] = conf[CONF_SCHEDULE_PROPS.START_TIME]
    roschatConf[ROSCHAT_CONF_SCHEDULE_PROPS.DURATION] = conf[CONF_SCHEDULE_PROPS.END_TIME] - conf[CONF_SCHEDULE_PROPS.START_TIME]
    roschatConf[ROSCHAT_CONF_SCHEDULE_PROPS.TOPIC] = conf[CONF_SCHEDULE_PROPS.TOPIC]
    roschatConf[ROSCHAT_CONF_SCHEDULE_PROPS.PASSWORD] = conf[CONF_SCHEDULE_PROPS.PASSWORD]
    const settings = {}
    if (!conf[CONF_SCHEDULE_PROPS.VIDEO_DISABLED]) settings[ROSCHAT_CONF_SETTINGS_PROPS.VIDEO] = true
    if (conf[CONF_SCHEDULE_PROPS.WAITING]) settings[ROSCHAT_CONF_SETTINGS_PROPS.WAITING] = true
    if (conf[CONF_SCHEDULE_PROPS.AT_ONCE]) settings[ROSCHAT_CONF_SETTINGS_PROPS.AT_ONCE] = true
    roschatConf[ROSCHAT_CONF_SCHEDULE_PROPS.SETTINGS] = settings
    if (conf[CONF_SCHEDULE_PROPS.PERIODICITY]) {
        let type
        let roschatDays = []
        let periodicity = conf[CONF_SCHEDULE_PROPS.PERIODICITY]
        let repeat = { [ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS.NUM]: periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.COUNT] }
        switch (periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.TYPE]) {
            case CONF_SCHEDULE_PERIODICITY_TYPES.DAY:
                type = ROSCHAT_CONF_SCHEDULE_PERIODICITY_TYPES.DAY
                break
            case CONF_SCHEDULE_PERIODICITY_TYPES.WEEK:
                type = ROSCHAT_CONF_SCHEDULE_PERIODICITY_TYPES.WEEK
                let days = periodicity[CONF_SCHEDULE_PERIODICITY_PROPS.DAYS]
                if (!days) break
                if (days) {
                    if (days.includes(CONF_SCHEDULE_PERIODICITY_DAYS.MN)) roschatDays.push(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.MN)
                    if (days.includes(CONF_SCHEDULE_PERIODICITY_DAYS.TU)) roschatDays.push(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.TU)
                    if (days.includes(CONF_SCHEDULE_PERIODICITY_DAYS.WE)) roschatDays.push(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.WE)
                    if (days.includes(CONF_SCHEDULE_PERIODICITY_DAYS.TH)) roschatDays.push(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.TH)
                    if (days.includes(CONF_SCHEDULE_PERIODICITY_DAYS.FR)) roschatDays.push(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.FR)
                    if (days.includes(CONF_SCHEDULE_PERIODICITY_DAYS.SA)) roschatDays.push(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.SA)
                    if (days.includes(CONF_SCHEDULE_PERIODICITY_DAYS.SU)) roschatDays.push(ROSCHAT_CONF_SCHEDULE_PERIODICITY_DAYS.SU)
                }
                break
            case CONF_SCHEDULE_PERIODICITY_TYPES.MONTH:
                type = ROSCHAT_CONF_SCHEDULE_PERIODICITY_TYPES.MONTH
                break
        }
        repeat[ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS.TYPE] = type
        if (roschatDays.length) repeat[ROSCHAT_CONF_SCHEDULE_PERIODICITY_PROPS.DAYS] = roschatDays
        roschatConf[ROSCHAT_CONF_SCHEDULE_PROPS.REPEAT] = repeat
    }

    return roschatConf
}

export function getRoschatIdObj(conf) {
    return {
        [ROSCHAT_CONF_SCHEDULE_PROPS.MEETING_ID]: conf[CONF_SCHEDULE_PROPS.ID]
    }
}

const testModule = ({ ...module })

Object.assign(testModule.state, state)
Object.assign(testModule.getters, getters)
Object.assign(testModule.actions, actions)
Object.assign(testModule.mutations, mutations)

export default testModule