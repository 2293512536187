//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapGetters, mapActions } from 'vuex'
    import { CLIENTDATA } from "../../store/modulesNames"
    import { GET_FONTS_SETTINGS } from "../../store/gettersTypes"
    import { ACT_SET_FONTS_SETTINGS } from "../../store/actionsTypes"

    import ModalDialog from './ModalDialog.vue'
    import modal_mixin from './modal-mixin'

    import VueSlider from 'vue-range-slider'
import event_bus from '../../eventBus'

    export default {
        name: "FontsSettings",
        components: { ModalDialog, VueSlider },
        mixins: [modal_mixin],
        data() {
            return {
                compModalProps: {
                    delay: 200,
                    pivotY: 0.40,
                    pivotX: 0.73,
                    width: 510,
                    height: 'auto',
                    adaptive: true,
                    styles: "min-width: 300px;"
                },
                sliderDisabled: false,
                restoring: false,
                titleFontSize: 16,
                titleFontSizeSliderValue: 50,
                subTitleFontSize: 14,
                subTitleFontSizeSliderValue: 50,
                messageFontSize: 14,
                messageFontSizeSliderValue: 50,
                fontContrast: 1,
                fontContrastSliderValue: 50,
            }
        },
        mounted() {
            const fontsSettings = this[GET_FONTS_SETTINGS]
            // console.log("🚀 ~ mounted ~ fontsSettings:", fontsSettings)
            const { 
                titleFontSizeSliderValue,
                subTitleFontSizeSliderValue,
                messageFontSizeSliderValue,
                fontContrastSliderValue,
            } = fontsSettings
            if (!!titleFontSizeSliderValue) this.titleFontSizeSliderValue = titleFontSizeSliderValue
            if (!!subTitleFontSizeSliderValue) this.subTitleFontSizeSliderValue = subTitleFontSizeSliderValue
            if (!!messageFontSizeSliderValue) this.messageFontSizeSliderValue = messageFontSizeSliderValue
            if (!!fontContrastSliderValue) this.fontContrastSliderValue = fontContrastSliderValue
        },
        computed: {
            title() {
               return this.$t('settings.fonts')
            },
            fontsStyles() {
                const style = {}
                Object.assign(style, {
                    '--titleFontSize': this.titleFontSize + 'px',
                    '--subTitleFontSize': this.subTitleFontSize + 'px',
                    '--messageFontSize': this.messageFontSize + 'px',
                    '--fontContrast': this.fontContrast,
                })
                return style
            },
            fontsSettingsObject() {
                return { 
                    titleFontSize: this.titleFontSize,
                    titleFontSizeSliderValue: this.titleFontSizeSliderValue,
                    subTitleFontSize: this.subTitleFontSize,
                    subTitleFontSizeSliderValue: this.subTitleFontSizeSliderValue,
                    messageFontSize: this.messageFontSize,
                    messageFontSizeSliderValue: this.messageFontSizeSliderValue,
                    fontContrast: this.fontContrast,
                    fontContrastSliderValue: this.fontContrastSliderValue,
                }
            },
            ...mapGetters(CLIENTDATA, [GET_FONTS_SETTINGS]),
        },
        watch: {
            titleFontSizeSliderValue(newVal, oldVal) {
                let delta = (newVal - oldVal) / 5
                this.titleFontSize += delta
                // console.log("🚀 ~ titleFontSizeSliderValue ~ this.titleFontSize:", this.titleFontSize)
            },
            subTitleFontSizeSliderValue(newVal, oldVal) {
                let delta = (newVal - oldVal) / 5
                this.subTitleFontSize += delta
            },
            messageFontSizeSliderValue(newVal, oldVal) {
                let delta = (newVal - oldVal) / 5
                this.messageFontSize += delta
            },
            fontContrastSliderValue(newVal, oldVal) {
                let delta = 2*(newVal - oldVal) / 100
                // console.log("🚀 ~ fontContrastSliderValue ~ delta:", delta)
                let changeFontContrast = this.fontContrast + delta
                this.fontContrast = +(changeFontContrast.toFixed(2))
                // console.log("🚀 ~ fontContrastSliderValue ~ this.fontContrast:", this.fontContrast)
            },
        },
        methods: {
            okBtn() {
                const fontsSettings = this.fontsSettingsObject
                this[ACT_SET_FONTS_SETTINGS](fontsSettings)
                event_bus.$emit('fonts-change')
                this.cancelBtn()
            },
            cancelBtn() {
                this.modalClose();
            },
            ...mapActions(CLIENTDATA, [ACT_SET_FONTS_SETTINGS])
        },
    }
