var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "side-list-item chat-list-item",
        class: { selected: _vm.isSelected, focused: _vm.focused },
        attrs: {
          title: _vm.chatTitle,
          "data-lastmessageid": _vm.chat.lastMessageId
        },
        on: {
          mouseover: function($event) {
            return _vm.mouseOver($event)
          },
          click: function($event) {
            return _vm.doSelectChat(_vm.chat.cidType, _vm.chat.cid)
          },
          dragover: _vm.chatItemDragover,
          dragenter: _vm.chatItemDragenter,
          drop: _vm.chatItemDrop,
          dragleave: _vm.chatItemDragleave,
          contextmenu: function($event) {
            $event.preventDefault()
            return _vm.doMenu($event)
          }
        }
      },
      [
        _c("icon-image", {
          attrs: { src: _vm.user.photo, text: _vm.user.fio }
        }),
        _c("div", { staticClass: "fio-block" }, [
          _c("div", { staticClass: "fio name" }, [
            _vm.chat.cidType === "group"
              ? _c("i", { staticClass: "item-icon fa fa-users" })
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.chat.name))]),
            _vm.chat.official
              ? _c("div", { staticClass: "official" }, [
                  _c("i", { staticClass: "far fa-check" })
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "text", class: _vm.isSystemMessageClass },
            [
              _vm.isOwnLastMessage
                ? _c("div", { staticClass: "status" }, [
                    _vm.isWatchedLastMessage
                      ? _c("i", { staticClass: "far fa-check-double" })
                      : _vm.isReceivedLastMessage
                      ? _c("i", { staticClass: "far fa-check-double greyFont" })
                      : _c("i", { staticClass: "far fa-check greyFont" })
                  ])
                : _vm._e(),
              _vm.typing
                ? _c("typing-status", {
                    attrs: { cid: _vm.chat.cid, cidType: _vm.chat.cidType }
                  })
                : [
                    _vm.draft
                      ? _c("span", { staticClass: "draft" }, [
                          _vm._v(_vm._s(_vm.$t("draft") + " "))
                        ])
                      : _vm._e(),
                    _c("span", {
                      staticClass: "last",
                      domProps: { innerHTML: _vm._s(_vm.text) }
                    })
                  ]
            ],
            2
          )
        ]),
        _c("chat-item-right-block", { attrs: { item: _vm.chat } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }