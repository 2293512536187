/**
 * Created by Aleksey on 18.07.2018.
 */
import Vue from 'vue';
import proto from "../../protocol";
import {uniq, equals, pickBy} from 'ramda'

import {
    GET_INFO_HISTORY_LENGTH,
    GET_INFO_OPEN,
    GET_MAIN_TYPE,
    GET_SELECTED_CHAT,
    GET_CHATS,
    GET_CHAT,
    GET_CHAT_MUTE_TIME,
    IS_CHAT_READ_ONLY,
    GET_UID,
    GET_CONTACT_BY_ID,
    GET_CHAT_MEMBERS,
    GET_IS_CHAT_MEMBER,
    IS_CHAT_ADMIN,
    GET_CHAT_MEMBER_INDEX,
    GET_IS_CHAT_OPENED,
    GET_CHAT_SCROLL_UP,
    GET_CHAT_TYPINGS,
    GET_CHAT_TYPING_CONTACTS,
    GET_MERGED_CONTACT_BY_ID,
    GET_CHAT_SAVED_DATA,
    GET_CHAT_DRAFT,
    GET_CHAT_POSITION,
    CAN_WRITE_TO_CHAT,
    IS_OWN_CHAT,
    IS_NOTE_CHAT,
    GET_BOT_BY_ID,
    GET_CHAT_LAST_MESSAGE,
    GET_CHAT_BY_LAST_MESSAGE,
    GET_CHAT_ATTACHED_MESSAGE_ID,
    GET_CHAT_EDIT_MESSAGE_ID,
    GET_CHATT_REPLY_MESSAGE_ID,
    GET_CHAT_IS_SYSTEM_MESSAGE,
    IS_CHAT_PINNED,
    GET_IS_ROLES_SUPPORTED,
    GET_SERVER_REMOTE_HOST,
} from '../gettersTypes'
import {
    ACT_ADD_CHAT,
    ACT_CHANGE_CHAT,
    ACT_CHANGE_CHAT_SETTINGS,
    ACT_INFO_PUSH,
    ACT_SELECTED_CHAT,
    ACT_REMOVE_CHAT,
    ACT_ADD_NOTIFICATION,
    ACT_ADD_TYPING_CID,
    ACT_DEL_TYPING_CID,
    ACT_SET_MSG_RECEIVED,
    ACT_WATCH_MESSAGES,
    ACT_WATCH_ALL_MESSAGES,
    ACT_WATCH_ALL_CHAT_MESSAGES,
    ACT_WATCH_MESSAGE,
    ACT_UPDATE_UNWATCHED_MESSAGES,
    ACT_UPDATE_CHAT_LAST_MESSAGE,
    ACT_SET_CHAT_DRAFT,
    ACT_SET_CHAT_POSITION,
    ACT_HANDLE_MESSAGE_EVENT,
    ACT_SEND_MESSAGE,
    ACT_BOT_REMOVE,
    ACT_CHAT_UPDATE_REPLY,
    ACT_HANDLE_MESSAGE_CHANGE_EVENT,
    ACT_HANDLE_MESSAGE_UPDATE_EVENT,
    ACT_SEND_CONTACT_MESSAGE,
    ACT_SEND_GEO_MESSAGE,
    ACT_SEND_POOL_MESSAGE,
    ACT_SEND_FILE_MESSAGE,
    ACT_SET_CHAT_MUTED,
    ACT_SET_CHAT_MARKED,
    ACT_SET_CHAT_PINED,
    ACT_UPDATE_CHAT,
    ACT_CHATS_ADD_MEMBERS_DIALOG,
    ACT_CHATS_ADD_MEMBERS,
    ACT_CHATS_REMOVE_MEMBER_DIALOG,
    ACT_CHATS_REMOVE_MEMBERS,
    ACT_MODAL_OPEN,
    ACT_REMOVE_CHAT_API,
    ACT_OPEN_CUSTOM_NOTIFICATION,
    ACT_BUILD_FILE_MESSAGE,
    ACT_SEND_DATA_MESSAGE,
    ACT_BUILD_POOL_MESSAGE,
    ACT_BUILD_GEO_MESSAGE,
    ACT_BUILD_CONTACT_MESSAGE,
    ACT_LEAVE_FROM_CHAT,
    ACT_SEND_TYPING_EVENT,
    ACT_CHAT_UPDATE_MESSAGE,
    ACT_UPDATE_SEARCHED_CONTACT,
    ACT_MAIN_TYPE_CLEAR,
    ACT_REPLACE_MAIN_TYPE,
    ACT_UPDATE_CONTACT_STATUS,
    ACT_UPDATE_SINGLE_CONTACT,
} from '../actionsTypes'
import {
    MUT_SET_SELECTED_CHAT,
    ADD_UNWATCHED_MESSAGE,
    MUT_ADD_DELAYED_UNWATCHED_MESSAGE,
    MUT_REMOVE_DELAYED_UNWATCHED_MESSAGE,
    MUT_UPDATE_CHAT_LAST_MESSAGE,
    MUT_SAVE_CHAT_DATA,
} from '../mutationsTypes';
import {
    CONTENT_MANAGER,
    CONTACTS,
    INFO,
    USERDATA,
    NOTIFICATIONS,
    CHAT,
    BOTS,
    MODAL,
    DATA_MESSAGE_BUILDER,
} from '../modulesNames'

import {MAIN_TYPES} from "./content-manager"

import { i18n } from './../../../ext/i18n'

const locale = i18n.messages[i18n.locale]

const ChatsStore = class {
    constructor() {
        const self = this;
        this.state = {
            chats: [],
            unwatched: [],
            unwatchedTemp: [],
            chatsSavedData: {},
            selected: {
                cid: null,
                cidType: null
            },
            rev: 0,
            timerDelayNotification: null,
            msgIdDelayNotification: null,
            typing: [],
            typingTimeOut: {},
        };

        function getChat(chats, cid, cidType) {
            // return chats.find(chat => {
            //     return isSameChat(chat, {cid, cidType});
            // });
            let chat = chats.find(chat => chat.cid === cid && chat.cidType === cidType)
            return chat
        }

        function isSameChat (obj, obj2) {
            return obj.cid === obj2.cid && obj.cidType === obj2.cidType
        }

        this.getters = {
            [GET_SELECTED_CHAT](state) {
                // защита от модификации в коде (инфо в payloadPrepare удаляет cidType, может еще есть подобное)
                return JSON.parse(JSON.stringify(state.selected))
            },
            [GET_IS_CHAT_OPENED]: (state, getters, rootState, rootGetters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                let mainType
                if (cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
                    mainType = MAIN_TYPES.CHAT
                } else if (cid === rootGetters[`${USERDATA}/${GET_UID}`]) {
                    mainType = MAIN_TYPES.NOTE
                } else {
                    mainType = MAIN_TYPES.CONTACT
                }
                return Boolean( rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`] === mainType && state.selected && state.selected.cid === cid && state.selected.cidType === cidType);
            },
            [GET_CHAT_TYPINGS]: (state) => params => {
                return state.typing.filter(({typingCid, chat}) => {
                    return (!params.typingCid || params.typingCid === typingCid) && params.cid === chat.cid && params.cidType === chat.cidType
                })
            },
            [GET_CHAT_TYPING_CONTACTS]: (state, getters, rootState, rootGetters) => params => {
                return getters[GET_CHAT_TYPINGS](params).map(({typingCid}) => rootGetters['contacts/getMergedContactById'](typingCid))
            },
            [GET_CHATS](state) {
                return state.chats;
            },
            [GET_CHAT]: (state) => (params) => getChat(state.chats, params.cid, params.cidType),
            isMuted: state => params => {
                const chat = getChat(state.chats, params.cid, params.cidType);
                return chat && chat.settings && chat.settings.mute > date_helper.getCurrentUnixTime();
            },
            [GET_CHAT_MUTE_TIME]: state => params => {
                const chat = getChat(state.chats, params.cid, params.cidType);
                return (chat && chat.settings && chat.settings.mute) || 0;
            },
            [GET_CHAT_IS_SYSTEM_MESSAGE]: (state, getters) => ({cid, cidType}) => {
                const chat = getters[GET_CHAT]({cid, cidType})
                const lastMessage = chat.lastMessage
                return lastMessage && lastMessage.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM
            },
            isMarked(state) {
                return function(params) {
                    const chat = getChat(state.chats, params.cid, params.cidType);
                    return chat && chat.settings && chat.settings.marked;
                };
            },
            [IS_CHAT_PINNED]: state => params => {
                const chat = getChat(state.chats, params.cid, params.cidType)
                return Boolean(chat && chat.settings && chat.settings.pinned)
            },
            [IS_CHAT_READ_ONLY]: (state, getters) => (payload) => {
                let chat = getters[GET_CHAT](payload)
                return (chat && chat.readOnly) || false;
            },

            [CAN_WRITE_TO_CHAT]: (state, getters) => (payload) => {
                let canWrite = true
                canWrite = canWrite && (!getters[IS_CHAT_READ_ONLY](payload) || getters[IS_CHAT_ADMIN](payload))
                return canWrite
            },
            getUnwatched(state) {
                return function({cid, cidType, all = false}) {
                    return state.unwatched.filter((message) => {
                        return message.cid === cid && message.cidType === cidType && (all || !state.unwatchedTemp.find(({id}) => message.id === id))
                    });
                };
            },
            getTotalUnwatched(state) {
                return state.unwatched;
            },
            getChatUnwatchedMessages(state) {
                return function (params) {
                    return state.unwatched.reduce((prev, message) => {
                        if (params && isSameChat(params, message)) prev++;
                        return prev;
                    }, 0) ;
                };
            },
            getTotalUnwatchedMessages(state) {
                return state.unwatched.reduce((prev, message) => {
                    if (!self.getters.isMuted(state)(message)) prev++;
                    return prev;
                }, 0);
            },
            getKeyboard(state) {
                return function (cid) {
                    let chat = state.chats.find(function(item) {
                        return item.cid === cid;
                    });
                    return chat && chat.chatKeyboard;
                };
            },
            getKeyboardAction(state) {
                return function (cid) {
                    let chat = state.chats.find(function(item) {
                        return item.cid === cid;
                    });
                    if(chat && 'chatKeyboardAction' in chat) return chat.chatKeyboardAction;
                    else return 'none';
                };
            },
            [GET_CHAT_MEMBERS]: (state, getters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP, filters}) => {
                let chat = getters.getChat({cid, cidType})
                let contacts = chat && chat.contacts || []
                if (contacts.length) contacts = [...contacts].sort((a, b) => a.cid - b.cid)
                return !filters ? contacts : contacts.filter((contact) => {
                    try {
                        Object.entries(filters).forEach(([key,val]) => { if (!contact[key] || contact[key] !== val) throw new Error() })
                    } catch (e) {
                        return false
                    }
                    return true
                })
            },
            [GET_IS_CHAT_MEMBER]: (state, getters, rootState, rootGetters) => (payload = {}) => {
                let { cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER } = payload
                if (cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER) {
                    let contact = rootGetters[`${CONTACTS}/${GET_CONTACT_BY_ID}`](cid);
                    return ('cid' in contact);
                } else if (cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
                    let uid = rootGetters[`${USERDATA}/${GET_UID}`];
                    let chat = getters.getChat(payload);
                    return !!(chat && chat.contacts && chat.contacts.find((contact) => contact.cid === uid));
                }
                return false;
            },
            [IS_CHAT_ADMIN]: (state, getters, rootState, rootGetters) => (payload) => {
                if (payload.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
                    let uid = rootGetters[`${USERDATA}/${GET_UID}`];
                    let chat = getters.getChat(payload);
                    return !!(chat && chat.contacts && (chat.contacts.find((contact) => contact.cid === uid) || {}).privilege === 'admin');
                }
                return false;
            },
            [GET_CHAT_MEMBER_INDEX]: (state, getters, rootState, rootGetters) => ({memberCid, cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                if (!rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](memberCid).cid) {
                    return -1
                } else if (cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER) {
                    return 0
                } else {
                    return getters[GET_CHAT_MEMBERS]({cid,cidType}).findIndex(({cid}) => cid === memberCid)
                }
            },
            [GET_CHAT_SAVED_DATA]: (state) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER, key}) => {
                return state.chatsSavedData[cidType] && state.chatsSavedData[cidType][cid] && state.chatsSavedData[cidType][cid][key]
            },
            [GET_CHAT_POSITION]: (state, getters) => ({cid, cidType}) => {
                return getters[GET_CHAT_SAVED_DATA]({cid, cidType, key: 'position'}) || {}
            },
            [IS_OWN_CHAT]: (state, getters, rootState, rootGetters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                return cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER && rootGetters[`${USERDATA}/${GET_UID}`] === cid
            },
            [IS_NOTE_CHAT]: (state, getters, rootState, rootGetters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                return rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`] === MAIN_TYPES.NOTE && getters[IS_OWN_CHAT]({cid, cidType})
            },
            [GET_CHAT_LAST_MESSAGE]: (state, getters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                let chat = getters[GET_CHAT]({cid, cidType})
                return chat && chat.lastMessage
            },
            [GET_CHAT_BY_LAST_MESSAGE]: (state) => ({id}) => {
                let chat
                if (id) chat = state.chats.find((chat) => chat.lastMessage && chat.lastMessage.id === id)
                return chat
            },
            [GET_CHAT_ATTACHED_MESSAGE_ID]: (state, getters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                let chat = getters[GET_CHAT]({cid, cidType})
                return chat && chat.pinMessage
            },
            [GET_CHAT_DRAFT]: (state, getters) => ({cid, cidType}) => {
                //let draft = getters[GET_CHAT_SAVED_DATA]({cid, cidType, key: 'draft'})
                //return draft && !(draft instanceof Object) ? { text: draft } : draft
                const chat = getters[GET_CHAT]({cid, cidType})
                return chat && chat.settings && chat.settings.draft
            },
            [GET_CHATT_REPLY_MESSAGE_ID]: (state, getters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                let draft = getters[GET_CHAT_DRAFT]({cid, cidType})
                return draft && draft.replyId
            },
            [GET_CHAT_EDIT_MESSAGE_ID]: (state, getters) => ({cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) => {
                let draft = getters[GET_CHAT_DRAFT]({cid, cidType})
                return draft && draft.editId
            },
        };
        this.actions = {
            //@todo разобраться и переделать
            open({dispatch, rootGetters}, chat) {
                const openInfo = rootGetters[`${INFO}/${GET_INFO_OPEN}`];
                const infoHistoryLength = rootGetters[`${INFO}/${GET_INFO_HISTORY_LENGTH}`];
                if (openInfo && !infoHistoryLength) dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: openInfo.type, params: {}}, {root: true});
                dispatch(ACT_SELECTED_CHAT, chat)
            },
            //@todo разобраться и переделать
            [ACT_SELECTED_CHAT]({ dispatch, commit, rootGetters }, params) {
                commit(MUT_SET_SELECTED_CHAT, params);
                let mainType
                if (params.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
                    mainType = MAIN_TYPES.CHAT
                } else if (params.cid === rootGetters[`${USERDATA}/${GET_UID}`]) {
                    mainType = MAIN_TYPES.NOTE
                } else {
                    mainType = MAIN_TYPES.CONTACT
                }
                dispatch(`${CONTENT_MANAGER}/${ACT_REPLACE_MAIN_TYPE}`, {type: mainType, params: {cid: params.cid, cidType: params.cidType}}, {root: true})
            },
            async update({commit, rootGetters}) {
                let chats = await proto.getChats({})
                chats.forEach(chat => {
                    let remoteHost = ''
                    if (chat.objectId) {
                        remoteHost = rootGetters[`${USERDATA}/${GET_SERVER_REMOTE_HOST}`](chat.objectId)
                        chat.remoteHost = remoteHost
                    }
                    commit('updateChat', chat)
                })
                log('update: total ' + chats.length)
                let rev = await proto.getChatsLastRevision()
                commit('updateRev', rev)
            },
            async updateByMessageId({state, commit}, {minMessageId}) {
                const chats = await proto.getChats({minMessageId, count: 1});
                chats.forEach(chat => commit('updateChat', chat));
            },
            async checkForUpdates({state, commit, rootGetters}) {
                const rev = await proto.getChatsLastRevision();
                if (state.rev !== rev) {
                    const chats = await proto.getChatsChanges(state.rev);
                    chats.forEach(chat => {
                        let remoteHost = ''
                        if (chat.objectId) {
                            remoteHost = rootGetters[`${USERDATA}/${GET_SERVER_REMOTE_HOST}`](chat.objectId)
                            chat.remoteHost = remoteHost
                        }
                        commit('updateChat', chat)
                    })
                    commit('updateRev', rev);
                }
            },
            async [ACT_WATCH_MESSAGES]({ dispatch, commit, rootGetters }, { messages, cidType, cid }) {
                let watchedMsgs = []
                let uid = rootGetters['userdata/getUid']
                let maxMsgIdsForChats = {}

                //получаем сообщения с максимальным id для каждого чата т.к. просмотрев последнее мы просматриваем все предыдущие для чата
                messages.forEach((message) => {
                    let { id, cidType: mCidType, cid: mCid } =  message;
                    if (!id) return
                    if (message.type === 'out' || message.senderId === uid) return // наше сообщение
                    if ((cidType && cidType !== mCidType) || (cid && cid !== mCid)) return // не соответсвует нужному чату (если передали)
                    if ('watchedTime' in message) return // уже просмотренно

                    let key = `${mCidType}_${mCid}`
                    if (!maxMsgIdsForChats[key]) maxMsgIdsForChats[key] = message
                    else if (maxMsgIdsForChats[key].id < message.id) maxMsgIdsForChats[key] = message
                    commit(MUT_ADD_DELAYED_UNWATCHED_MESSAGE, message)
                    watchedMsgs.push(id)
                })

                try {
                    await Promise.all(Object.values(maxMsgIdsForChats).map(async (message = {}) => {
                        return await dispatch(ACT_WATCH_MESSAGE, {message, cidType, cid})
                    }))
                    commit('setMessagesWatched', watchedMsgs)
                } catch (e) {
                    dispatch(ACT_UPDATE_UNWATCHED_MESSAGES)
                }
            },
            async [ACT_WATCH_ALL_MESSAGES]({getters, dispatch}) {
                let messages = getters['getTotalUnwatched']
                dispatch(ACT_WATCH_MESSAGES, {messages})
            },
            async [ACT_WATCH_ALL_CHAT_MESSAGES]({getters, dispatch}, {cid, cidType}) {
                let messages = getters['getUnwatched']({cid, cidType})
                if (messages.length) dispatch(ACT_WATCH_MESSAGES, {messages})
            },
            async [ACT_WATCH_MESSAGE]({dispatch, commit, getters, rootGetters}, {message, cidType, cid}) {
                let { id } =  message;
                commit(MUT_ADD_DELAYED_UNWATCHED_MESSAGE, message)
                await proto.setMessageWatchedAsync(id)
            },
            async [ACT_UPDATE_UNWATCHED_MESSAGES]({commit}, params) {
                let messages = await proto.getUnwatchedMessagesAsync([])
                commit('updateUnwatched', messages)
            },
            updateUnwatchedMessage({state, commit, rootGetters}, payload) {
                commit('updateUnwatchedMessage', payload)
            },
            async [ACT_REMOVE_CHAT_API]({dispatch, commit, getters, rootGetters}, chat) {
                if (!getters[GET_CHAT](chat)) return
                const { cid, cidType } = chat
                const isBot = cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER &&
                    rootGetters[`${BOTS}/${GET_BOT_BY_ID}`](cid)
                if (isBot) await dispatch(`${BOTS}/${ACT_BOT_REMOVE}`, chat, {root: true})
                else await proto.deleteChat(cidType, cid)
                dispatch(ACT_REMOVE_CHAT, chat)
            },
            async [ACT_REMOVE_CHAT]({getters, dispatch, commit}, chat) {
                if (!getters[GET_CHAT](chat)) return
                const opened = getters[GET_IS_CHAT_OPENED](chat)
                commit('setMessagesWatched', getters['getUnwatched'](chat).map(({id}) => id))
                commit('removeChat', chat)
                if (opened) {
                    dispatch(`${CONTENT_MANAGER}/${ACT_MAIN_TYPE_CLEAR}`, null, {root: true})
                }
            },
            async [ACT_ADD_CHAT]({dispatch}, {name, icon, contacts}) {
                try {
                    const cid = await proto.addChat({name, icon, contacts})
                    dispatch('checkForUpdates') //@todo update конкретного чата
                    //obj.commit('updateChat', {cid, cidType: 'group', name, icon, contacts});
                } catch (e) {}
            },
            async [ACT_CHANGE_CHAT]({commit, dispatch}, params) {
                try {
                    await proto.changeChat(params)
                    //let rev = await proto.changeChat(params)
                    //@todo сейчас на собственное изменение прилетает эвент "chat-change-event" и менять модель самим нет смысла, вернуть в случаи отсутсвия эвента
                    /*let { cid, cidType, deleteContacts = [], addContacts = [], ...payload } = params
                    if (Object.keys(payload).length) dispatch(ACT_UPDATE_CHAT, { cid, cidType, rev, ...payload })
                    if (addContacts.length) commit('addMembers', { cid, cidType, addContacts })
                    if (deleteContacts.length) commit('delMembers', { cid, cidType, deleteContacts })*/
                } catch (e) {}
            },
            async [ACT_UPDATE_CHAT]({dispatch, rootGetters, commit}, {rev, deleted, objectId, ...payload}) {
                if (deleted) dispatch(ACT_REMOVE_CHAT, payload)
                else {
                    let remoteHost = ''
                    if (objectId) {
                        remoteHost = rootGetters[`${USERDATA}/${GET_SERVER_REMOTE_HOST}`](objectId)
                        payload.remoteHost = remoteHost
                    }
                    commit('updateChat', payload)
                }
                if (rev) commit('updateRev', rev)
            },
            [ACT_CHANGE_CHAT_SETTINGS]({state, dispatch}, {cid, cidType, ...newSettings}) {
                const chat = getChat(state.chats, cid, cidType)
                if (!chat) return
                const settings = chat && chat.settings ? JSON.parse(JSON.stringify(chat.settings)) : {}
                for (let i in newSettings) {
                    let newVal = newSettings[i]
                    if (newVal === null) delete settings[i]
                    else settings[i] = newVal
                }
                if (!equals(settings, chat.settings)) {
                    const data = {cid, cidType, settings}
                    dispatch(ACT_CHANGE_CHAT, data)
                }
            },
            [ACT_SET_CHAT_MUTED]({dispatch}, { cidType, cid, mute = null }) {
                dispatch(ACT_CHANGE_CHAT_SETTINGS, { cidType, cid, mute })
            },
            [ACT_SET_CHAT_MARKED]({dispatch}, { cidType, cid, marked = null }) {
                dispatch(ACT_CHANGE_CHAT_SETTINGS, { cidType, cid, marked })
            },
            [ACT_SET_CHAT_PINED]({dispatch}, { cidType, cid, pinned = null }) {
                dispatch(ACT_CHANGE_CHAT_SETTINGS, { cidType, cid, pinned })
            },
            async [ACT_SET_CHAT_DRAFT]({dispatch, commit, getters}, { cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER, draft = null }) {
                dispatch(ACT_CHANGE_CHAT_SETTINGS, { cidType, cid, draft })
            },
            [ACT_CHATS_ADD_MEMBERS_DIALOG]({dispatch}, payload) {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'select-contacts-to-chat',
                    props: payload
                }, {root: true})
            },
            async [ACT_CHATS_ADD_MEMBERS]({dispatch, rootGetters}, {chat_cid: cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP, contacts: addContacts}) {
                if (rootGetters[`${CONTACTS}/${GET_IS_ROLES_SUPPORTED}`]) {
                    addContacts.forEach(async contact => {
                        const isFoundContact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](contact.cid)
                        if (!isFoundContact.cid) {
                            await dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid: contact.cid}, {root: true})
                        }
                    })
                }
                dispatch(ACT_CHANGE_CHAT, {cid, cidType, addContacts})
            },
            [ACT_CHATS_REMOVE_MEMBER_DIALOG]({dispatch}, {chat_cid, cid}) {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'remove-chat-member',
                    props: {chat_cid, cid}
                }, {root: true})
            },
            async [ACT_CHATS_REMOVE_MEMBERS]({rootGetters, commit, dispatch}, {chat_cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP, contacts}) {
                let deleteContacts = contacts.filter(({cid}) => {
                    let bot = rootGetters[`${BOTS}/${GET_BOT_BY_ID}`](cid)
                    if (bot) dispatch(`${BOTS}/${ACT_BOT_REMOVE}`, {cid: chat_cid, cidType, botId: cid}, {root: true})
                    else return true
                })
                if (deleteContacts.length) dispatch(ACT_CHANGE_CHAT, {cid: chat_cid, cidType, deleteContacts})
            },
            [ACT_ADD_TYPING_CID]({getters, commit}, params) {
                !getters[GET_CHAT_TYPINGS](params)[0] && commit('addTypingCid', params)
            },
            [ACT_DEL_TYPING_CID]({getters, commit}, params) {
                const typing = getters[GET_CHAT_TYPINGS](params)[0]
                getters[GET_CHAT_TYPINGS](params)[0] && commit('delTypingCid', typing)
            },
            async [ACT_SET_MSG_RECEIVED]({getters, commit}, params) {
                try {
                    await proto.setMessageReceived(params.id)
                } catch (e) {}

            },
            async [ACT_UPDATE_CHAT_LAST_MESSAGE]({getters, dispatch, commit}, message) {
                let { cid, cidType } = message
                let chat = getters[GET_CHAT]({ cid, cidType })
                if (chat) {
                    commit(MUT_UPDATE_CHAT_LAST_MESSAGE, {chat, message})
                } else {
                    await dispatch('updateByMessageId', { minMessageId: message.id })
                    await dispatch(ACT_UPDATE_UNWATCHED_MESSAGES)
                }
            },
            [ACT_SET_CHAT_POSITION]({commit}, { cidType, cid, position }) {
                commit(MUT_SAVE_CHAT_DATA, { cidType, cid, key: 'position', payload: position })
            },
            async [ACT_SEND_MESSAGE]({dispatch, getters, rootGetters}, message) {
                if (getters[GET_IS_CHAT_OPENED](message)) {
                    let replyId = rootGetters[`${CHAT}/${GET_CHATT_REPLY_MESSAGE_ID}`]
                    if (replyId) {
                        message.replyId = replyId
                        dispatch(`${CHAT}/${ACT_CHAT_UPDATE_REPLY}`, null, {root: true})
                    }
                }
                let data = await proto.sendMessageAsync(message)
                if (data.error && data.error === 'dlp-forbidden') {
                    dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                        type: 'alert',
                        title: locale['dlp-alert-data-forbidden-title'],
                        subtitle: locale['dlp-alert-data-forbidden-message'],
                    }, {root: true})
                }
                return data
            },
            async [ACT_HANDLE_MESSAGE_EVENT]({state, dispatch, commit, getters, rootGetters}, payload) {
                let {cidType, cid, senderId, id} = payload
                let typing_data = { cidType, cid, typingCid: senderId }
                payload.time = 0
                switch (payload.dataType) {
                    case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED: {
                        // let typing_data = {cidType, cid, typingCid: senderId}
                        let timeout_cid = (cidType || 'user') + '/' + senderId
                        switch (payload.data.type) {
                            case 'message-writing':
                                if (payload.type === 'in') {
                                    if (state.typingTimeOut[timeout_cid]) clearTimeout(state.typingTimeOut[timeout_cid])
                                    dispatch('addTypingCid', typing_data)
                                    state.typingTimeOut[timeout_cid] = setTimeout(function () {
                                        dispatch('delTypingCid', typing_data)
                                        state.typingTimeOut[timeout_cid] = false
                                    }, 5500)
                                }
                                break
                        }
                        break
                    }
                    case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                        await dispatch(`${CHAT}/${ACT_CHAT_UPDATE_MESSAGE}`, { message: payload }, { root: true })
                        await dispatch(ACT_UPDATE_CHAT_LAST_MESSAGE, payload)
                        dispatch('updateUnwatchedMessage', { message: payload })
                        dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, { type: 'msg', cid, cidType, id }, {root: true})
                        break
                    case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                    case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                        let user = rootGetters['contacts/getMergedContactById'](cid)
                        let ownCid = rootGetters['userdata/getUid']
                        if (!('senderId' in payload)) payload.senderId = ownCid
                        let isOwnMessage = payload.senderId === ownCid
                        if (!user.cid && payload.cidType === 'user' && cid !== 0) {
                            await dispatch(`${CONTACTS}/${ACT_UPDATE_SINGLE_CONTACT}`, { cid, type: 'global' }, { root: true })
                        }

                        if (!isOwnMessage) commit('delTypingCid', typing_data)
                        await dispatch(`${CHAT}/${ACT_CHAT_UPDATE_MESSAGE}`, { message: payload }, { root: true })
                        await dispatch(ACT_UPDATE_CHAT_LAST_MESSAGE, payload)
                        !isOwnMessage && dispatch(ACT_SET_MSG_RECEIVED, payload)
                        dispatch('updateUnwatchedMessage', { message: payload })
                        dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, { type: 'msg', cid, cidType, id }, {root: true})
                        break
                }
                const isChatOpened = getters[GET_IS_CHAT_OPENED]({cid: senderId})
                if (isChatOpened) await dispatch(`${CONTACTS}/${ACT_UPDATE_CONTACT_STATUS}`, senderId, { root: true })
            },
            async [ACT_HANDLE_MESSAGE_CHANGE_EVENT]({state, dispatch, commit, getters, rootGetters}, message) {
                commit('chat/updateMessageProps', {id: message.id, message}, {root: true})
                await dispatch('updateByMessageId', { minMessageId: message.id }) // ? check if the update is optimized
                dispatch('updateUnwatchedMessage', { message })
                if ('deletedTime' in message) {
                    let chat = getters[GET_CHAT_BY_LAST_MESSAGE](message)
                    if (chat) {
                        await dispatch('checkForUpdates')
                        let lastMessage = getters[GET_CHAT_LAST_MESSAGE](chat)
                        if (lastMessage.id === message.id) commit('removeChat', chat)
                    }
                }
            },
            async [ACT_HANDLE_MESSAGE_UPDATE_EVENT]({dispatch, commit, getters}, message) {
                await dispatch(`${CHAT}/${ACT_CHAT_UPDATE_MESSAGE}`, { message }, { root: true })
                dispatch('updateUnwatchedMessage', { message })
                let lastMessage = getters[GET_CHAT_LAST_MESSAGE](message)
                if (!lastMessage || lastMessage.id === message.id) dispatch(ACT_UPDATE_CHAT_LAST_MESSAGE, message)
            },
            async [ACT_SEND_CONTACT_MESSAGE]({dispatch}, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) {
                let payload = await dispatch(`${DATA_MESSAGE_BUILDER}/${ACT_BUILD_CONTACT_MESSAGE}`, undefined, { root: true })
                return dispatch(ACT_SEND_DATA_MESSAGE, { cid, cidType, payload })
            },
            async [ACT_SEND_GEO_MESSAGE]({dispatch}, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) {
                let payload = await dispatch(`${DATA_MESSAGE_BUILDER}/${ACT_BUILD_GEO_MESSAGE}`, undefined, { root: true })
                return dispatch(ACT_SEND_DATA_MESSAGE, { cid, cidType, payload })
            },
            async [ACT_SEND_POOL_MESSAGE]({dispatch}, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) {
                let payload = await dispatch(`${DATA_MESSAGE_BUILDER}/${ACT_BUILD_POOL_MESSAGE}`, undefined, { root: true })
                return dispatch(ACT_SEND_DATA_MESSAGE, { cid, cidType, payload })
            },
            async [ACT_SEND_FILE_MESSAGE]({dispatch}, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER, text, fileGroupId, ...filePayload}) {
                let payload = await dispatch(`${DATA_MESSAGE_BUILDER}/${ACT_BUILD_FILE_MESSAGE}`, filePayload, { root: true })
                if (text) payload.text = text
                if (fileGroupId) payload.fileGroupId = fileGroupId
                return dispatch(ACT_SEND_DATA_MESSAGE, { cid, cidType, payload })
            },
            async [ACT_SEND_DATA_MESSAGE]({dispatch}, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER, payload}) {
                return dispatch(ACT_SEND_MESSAGE, {
                    cid,
                    cidType,
                    dataType: declarations.msgDataTypes.MSG_DATA_TYPE_DATA,
                    data: JSON.stringify(payload)
                })
            },
            [ACT_SEND_TYPING_EVENT](obj, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}) {
                proto.sendTypingEvent(cidType, cid)
            },
            [ACT_LEAVE_FROM_CHAT]({dispatch}, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP}) {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'confirm',
                    props: {
                        text: locale['leave-chat-conf'],
                        btnOk: {
                            async cb(dialog) {
                                await proto.exitChat(cidType, cid)
                                dispatch('update') // @todo обновлять конкретный чат
                                dialog.close()
                            }
                        }
                    }
                }, {root: true})
            },
        };
        this.mutations = {
            [MUT_SET_SELECTED_CHAT](state, params) {
                state.selected = params;
            },
            changeChat(state, params) {
                let chat = getChat(state.chats, params.cid, params.cidType),
                    index = state.chats.indexOf(chat);
                // icon = state.chats[index].icon;
                if (index < 0) return;
                for (let param in params) {
                    if (param !== 'photo') Vue.set(state.chats[index], param, params[param]);
                }
                if (chat.photo) Vue.set(chat, 'photo', chat.photo.split('?').shift() + "?t=" + new Date().getTime());
            },
            setTitle(state, params) {
                let chat = getChat(state.chats, params.cid, params.cidType),
                    index = state.chats.indexOf(chat);
                state.chats[index].title = params.data.title;
            },
            setIcon(state, params) {
                let chat = getChat(state.chats, params.cid, params.cidType),
                    index = state.chats.indexOf(chat);
                state.chats[index].icon = params.data.icon;
            },
            removeChat(state, params) {
                let chat = getChat(state.chats, params.cid, params.cidType)
                let index = state.chats.indexOf(chat)
                if (index !== -1) state.chats.splice(index, 1)
                if (state.chatsSavedData[params.cidType]) delete state.chatsSavedData[params.cidType][params.cid]
            },
            updateChat(state, chat) {
                let oldChat = getChat(state.chats, chat.cid, chat.cidType)
                if (!chat.lastMessage) chat.lastMessage = {}
                chat.lastMessage.unixTime = date_helper.secondsLeftToUnix(chat.lastMessage.time)
                if(!chat.name) chat.name = locale['search-comp']['group-chat']

                if (chat.cidType === 'group') {
                    if (!chat.icon) chat.photo = ''
                    else {
                        if (chat.hasOwnProperty('remoteHost')) {
                            let icon = chat.icon
                            if (chat.lastMessage && chat.lastMessage.data.type === declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_UPDATED)                             {
                                if (icon) chat.photo = `https://${chat.remoteHost}` + '/chats/icon/' + icon + '?t=' + new Date().getTime()
                            } else chat.photo = `https://${chat.remoteHost}` + '/chats/icon/' + icon + '?t=' + new Date().getTime()
                        } else chat.photo = utils.getPageProtoHostPort() + '/chats/icon/' + chat.icon + '?t=' + new Date().getTime()
                    }
                }

                if(chat.cid === 0) {
                    chat.name = locale['appname-caps']
                    chat.photo = 'img/system_chat_icon.png'
                    chat.official = true
                }

                let support = app.store.getters['contacts/getSupport']
                if(support && 'cid' in support && chat.cid === support.cid) chat.official = true

                if (oldChat) {
                    //index = state.chats.indexOf(chat);
                    //Vue.set(state.chats, index, chat);
                    let keys = uniq(Object.keys(oldChat).concat(Object.keys(chat)))
                    keys.forEach(key => {
                        if (!(key in oldChat) || !equals(oldChat[key], chat[key])) {
                            Vue.set(oldChat, key, chat[key])
                        } else if (!(key in chat)) {
                            Vue.delete(oldChat, key)
                        }
                    })
                } else {
                    state.chats.push(chat)
                }
            },
            addMembers(state, {cid, cidType, addContacts}) {
                const chat = getChat(state.chats, cid, cidType)
                chat && addContacts && addContacts.forEach(contact => {
                    const contact_index = chat.contacts.findIndex(_contact => {
                        return _contact.cid === contact.cid
                    })
                    if (contact_index < 0) chat.contacts.push(contact)
                    else chat.contacts.splice(contact_index, 1, contact)
                })
            },
            delMembers(state, {cid, cidType, deleteContacts}) {
                const chat = getChat(state.chats, cid, cidType)
                chat && deleteContacts && deleteContacts.forEach(contact => {
                    const contact_index = chat.contacts.findIndex(_contact => {
                        return _contact.cid === contact.cid
                    })
                    if (contact_index >= 0) chat.contacts.splice(contact_index, 1)
                })
            },
            /**
             * @param {Object} state
             * @param {Object} params
             * @param {Number} params.cid
             * @param {String} params.cidType
             * @param {Number} params.typingCid
             */
            addTypingCid(state, {cid, cidType, typingCid}) {
                state.typing.push({
                    typingCid,
                    chat: { cid, cidType}
                })
            },
            /**
             * @param {Object} state
             * @param {Object} typing
             */
            delTypingCid(state, typing) {
                state.typing.splice(state.typing.indexOf(typing), 1)
            },
            [MUT_UPDATE_CHAT_LAST_MESSAGE](state, {chat, message}) {
                message.unixTime = date_helper.secondsLeftToUnix(message)
                Vue.set(chat, 'lastMessage', message)
            },
            updateUnwatchedMessage(state, params) {
                let temp = self.state.unwatchedTemp;
                let check;
                for(let i = 0; i < temp.length; i++) {
                    if(params.message.id && params.message.id === temp[i].id) {
                        check = true
                        break
                    }
                }
                if(temp.length === 0 || !check) {
                    if (params.message.type && params.message.type === 'out') return;

                    let message = state.unwatched.find(cur => cur.id === params.message.id);
                    let watched = 'watchedTime' in params.message || (params.message.message && 'watchedTime' in params.message.message);
                    let deleted = 'deletedTime' in params.message || (params.message.message && 'deletedTime' in params.message.message);
                    let documentHidden = app.store.getters['content_manager/getDocumentHidden']
                    let documentFocus = app.store.getters['content_manager/getDocumentFocus']
                    let scrollUp = app.store.getters[`${CHAT}/${GET_CHAT_SCROLL_UP}`]
                    let activeChat = !documentHidden && documentFocus
                    let currentChat = state.visible && state.selected.cid === params.message.cid && state.selected.cidType === params.message.cidType
                    // let activeAndCurrentChat = state.selected.cid === params.message.cid && state.selected.cidType === params.message.cidType && !documentHidden && documentFocus

                    if (watched || deleted) {
                        if(params.message.id === state.msgIdDelayNotification) {
                            clearTimeout(state.timerDelayNotification);
                            app.store.dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {type: 'update'});
                            state.timerDelayNotification = null;
                            state.msgIdDelayNotification = null;
                        }
                        if(message) {
                            state.unwatched.splice(state.unwatched.indexOf(message), 1);
                            app.store.dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {type: 'update'});
                        }
                        self.mutations[MUT_REMOVE_DELAYED_UNWATCHED_MESSAGE](state, params.message)
                    } else if (!watched && params.message.type && !(currentChat && activeChat && !scrollUp)) {
                        state.msgIdDelayNotification = params.message.id;
                        state.timerDelayNotification = setTimeout(() => {
                            self.mutations[ADD_UNWATCHED_MESSAGE](state, {msg: params.message})
                        }, 1000)
                    }
                }
            },
            [ADD_UNWATCHED_MESSAGE](state, {msg}) {
                let include = [...state.unwatched, ...state.unwatchedTemp].some(item => {
                    return item.id === msg.id
                })
                if(!include) state.unwatched.push(msg);
            },
            clear(state) {
                state.chats.splice(0, state.chats.length);
            },
            updateKeyboard(state, params) {
                let index = state.chats.findIndex(function(item) {
                    return item.cid === params.cid && item.cidType === params.cidType;
                });
                if(index === -1) return;
                if(params.action === 'remove') state.chats[index].chatKeyboard = [];
                else {
                    Vue.set(state.chats[index], 'chatKeyboard', params.keyboard);
                    Vue.set(state.chats[index], 'chatKeyboardAction', params.action);
                }
            },
            updateRev(state, rev) {
                state.rev = rev;
            },
            setMessagesWatched(state, messages = []) {
                let copy = state.unwatched.slice()
                let i = copy.length
                let idsSet = new Set(messages)
                while (i--) {
                    if (idsSet.has(copy[i].id)) {
                        copy.splice(i, 1)
                    }
                }
                Vue.set(state, 'unwatched', copy)
            },
            updateUnwatched(state, messages = []) {
                state.unwatched.splice(0, state.unwatched.length)
                state.unwatched.push(...messages)
            },
            [MUT_ADD_DELAYED_UNWATCHED_MESSAGE]({unwatchedTemp}, message) {
                if (!unwatchedTemp.find((item) => item === message.id)) unwatchedTemp.push(message)
            },
            [MUT_REMOVE_DELAYED_UNWATCHED_MESSAGE]({unwatchedTemp}, message) {
                let index = unwatchedTemp.findIndex((item) => item === message.id)
                if (index >= 0) unwatchedTemp.splice(index, 1)
            },
            [MUT_SAVE_CHAT_DATA]({chatsSavedData}, {cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER, key, payload}) {
                if (!chatsSavedData[cidType]) Vue.set(chatsSavedData, cidType, {})
                let tempObj = chatsSavedData[cidType]
                if (!tempObj[cid]) Vue.set(tempObj, cid, {})
                tempObj = tempObj[cid]
                if (payload) {
                    Vue.set(tempObj, key, payload)
                } else {
                    Vue.delete(tempObj, key)
                }
            },
        };
    }

    get store() {
        return {
            namespaced: true,
            state: this.state,
            getters: this.getters,
            actions: this.actions,
            mutations: this.mutations
        };
    }
};

const chatsStore = new ChatsStore().store;
export default chatsStore;
