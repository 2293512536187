//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {QrcodeDropZone, QrcodeCapture} from 'vue-qrcode-reader';
import login_mixin from './login-mixin';
import {mapGetters, mapActions} from 'vuex';
import {ACT_LOGIN, ACT_SET_APP_SERVER} from "../../store/actionsTypes";
import {LOGIN, USERDATA} from "../../store/modulesNames";
import CustomCheckbox from "../custom/CustomCheckbox.vue";

export default {
    name: "QrFile",
    mixins: [login_mixin],
    components: {QrcodeDropZone, QrcodeCapture, CustomCheckbox},
    data() {
        return {
            result: null,
            dragover: false
        }
    },
    methods: {
        doQrFileSelect(e) {
            this.fileElement = e.target.nextElementSibling
            this.fileElement.click()
        },
        async onDetect (promise) {
            function promiseTimeout(ms, promise){

                return new Promise(function(resolve, reject){

                    // create a timeout to reject promise if not resolved
                    var timer = setTimeout(function(){
                        let error = new Error("Promise timeout")
                        error.name = "Timeout"
                        reject(error)
                    }, ms)

                    promise
                        .then(function(res){
                            clearTimeout(timer)
                            resolve(res)
                        })
                        .catch(function(err){
                            clearTimeout(timer)
                            reject(err)
                        })
                })
            }
            try {
                const { content } = await promiseTimeout(1000, promise)
                console.log('resolve', content)
                if (!content) {
                    let error = new Error("Image haven't data")
                    error.name = "NoData"
                    throw error
                }
                this.result = content
                //this.errors.splice(0, this.errors.length)
            } catch (error) {
                let error_msg
                switch (error.name) {
                    case 'DropImageDecodeError':
                        error_msg = this.$t('errors.not-image')
                        break;
                    case 'NoData':
                        error_msg = this.$t('errors.no-file-data')
                        break;
                    default: error_msg = this.$t('errors.error-reading-qr')
                }
                this.showAlert(error_msg)
            }
        },
        logErrors (promise) {
            promise.catch(console.error)
        },
        onDragOver (isDraggingOver) {
            this.dragover = isDraggingOver
        },
        async onDecode (result) {
            let error = false
            try {
                result = JSON.parse(result)
            } catch (e) {
                error = true
            }

            error = error || (this.isElectron && !result.server)

            if (error) {
                this.fileElement.value = null
                return this.showAlert(this.$t('errors.required-data-absent'))
            }

            try {
                this.isElectron && await this.doSetServer(result.server)
            } catch (e) {
                console.warn(e)
                this.fileElement.value = null
                return this.showAlert(this.getLoginError('not-connected'))
            }

            this.doLogin(result)
            this.fileElement.value = null
        },
        async doSetServer(server) {
            this[ACT_SET_APP_SERVER](server)
            await app.init(false)
        },
        async doLogin(data) {
            let error_msg,
                login = data.login,
                password = data.password
            
            if (!login) error_msg = this.$t('login-not-def')
            if (!error_msg && !password) error_msg = this.$t('pass-not-def')

            if (error_msg) return this.showAlert(error_msg)

            try {
                this.$emit('logging')
                const remember_me = this.isElectron ? true : this.remember_me
                let data = await this[ACT_LOGIN]({login, password, remember_me})
                if(data.status === 'pin-required') this.$parent.doAdd('2FA', {login, password, remember_me, uid: data.uid})
            } catch (e) {
                this.showAlert(this.getLoginError(e))
            }
        },
        ...mapActions(LOGIN, [ACT_LOGIN]),
        ...mapActions(USERDATA, [ACT_SET_APP_SERVER]),
    },
    mounted() {
        let self = this
        this.$el.querySelector('.drop-area').addEventListener('dragover', (e) => {
            self.dragover = true
        })
        this.$el.querySelector('.drop-area').addEventListener('dragleave', (e) => {
            self.dragover = false
        })
    }
}
