(function(name, definition) {
    if (typeof module != 'undefined') module.exports = definition();
    else if (typeof define == 'function' && typeof define.amd == 'object') define(definition);
    else this[name] = definition();
}('mod', function() {
    //This is the code you would normally have inside define() or add to module.exports
    return {
       APP_NAME: "SpecLink",
       APP_NAME_RU: "SpecLink",
       APP_NAME_LOW: "speclink",
       APP_DESCRIPTION: "Corporate Messenger",
       APP_DESCRIPTION_RU: "Корпоративный мессенджер",
       VERSION: "5.1.1867",
       DEFAULT_VM_SERVER: "",
       DEFAULT_CLIENT_UPDATE_SERVER: ""
    };
}));