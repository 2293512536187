//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import event_bus from '../../eventBus'
import {mapGetters, mapActions} from 'vuex'
import {RADIO, SOCKET} from '../../store/modulesNames'
import {
    GET_CURRENT_RADIO,
    GET_RADIO_PARAMS,
    RADIO_IS_TURN_ON,
    GET_CONNECTION_STATUS,
} from '../../store/gettersTypes'
import {ACT_RADIO_STATE_TOGGLE, ACT_SET_CURRENT_RADIO} from '../../store/actionsTypes'
import TetraRadio from './TetraRadio.vue'
import RcRadio from './RcRadio.vue'
import {RADIO_TYPE} from '../../store/modules/radio'
import RadioSelect from "../modal/RadioSelect.vue"
import { i18n } from '../../../ext/i18n'

export default {
    name: "Radio",
    components: { TetraRadio, roschat: RcRadio },
    computed: {
        btnOn() {
          return this.isTurnOn && this.socketConnected
        },
        socketConnected() {
            return this[GET_CONNECTION_STATUS] === 'connected'
        },
        selectionIsAvailable() {
          return this.radioParams.length > 1
        },
        ...mapGetters(RADIO, {
            currentRadio: GET_CURRENT_RADIO,
            radioParams: GET_RADIO_PARAMS,
            isTurnOn: RADIO_IS_TURN_ON
        }),
        ...mapGetters(SOCKET, [GET_CONNECTION_STATUS]),
    },
    methods: {
        openRadioSelect() {
            this.selectionIsAvailable && this.modalOpen({
                component: RadioSelect
            })
        },
        ...mapActions(RADIO, {
            [ACT_SET_CURRENT_RADIO]: ACT_SET_CURRENT_RADIO,
            radioStateToggle: ACT_RADIO_STATE_TOGGLE
        })
    },
    watch: {
        socketConnected(val) {
            if (val && this.isTurnOn) {
                this.radioStateToggle()
                event_bus.$once('loggedin', this.radioStateToggle)
            }
        }
    },
    filters: {
        headerText(radio) {
            let locale = i18n.messages[i18n.locale]
            if (!radio || !radio.type) return locale['select-radio']
            let text = locale['unknown-type']
            switch (radio.type) {
                case RADIO_TYPE.COMMON:
                    text = locale['radio']
                    break
                case RADIO_TYPE.TETRA:
                    text = `${radio.info} (${radio.ssi})`
                    break
            }
            return text
        }
    }
}
