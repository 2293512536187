//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import proto from '../../protocol';
import SelectServerInput from './SelectServerInput.vue';
import login_mixin from "./login-mixin"
import {USERDATA} from "../../store/modulesNames"
import { GET_APP_SERVER, GET_APP_LAST_SERVER } from "../../store/gettersTypes"
import {ACT_SET_APP_SERVER} from "../../store/actionsTypes"
import {mapGetters, mapActions} from 'vuex'

export default {
    name: "SelectServer",
    components: {SelectServerInput},
    mixins: [login_mixin],
    data() {
        return {
            servers: [],
            server_index: null,
            connecting: false,
            server: '',
        }
    },
    computed: {
       ...mapGetters(USERDATA, { selectedServer: GET_APP_SERVER }),
       ...mapGetters(USERDATA, { lastServer: GET_APP_LAST_SERVER })
    },
    methods: {
        async doSetServer() {
            this.connecting = true
            this[ACT_SET_APP_SERVER](this.server)
            try {
                await app.init(false);
                this.$parent.doNext();
            } catch (e) {
                console.warn(e);
                this.showAlert(this.$t('errors.not-connected'));
            }
            this.connecting = false
        },
        ...mapActions(USERDATA, [ACT_SET_APP_SERVER])
    },
    async created() {
        try {
            this.server = this.selectedServer
            if (!this.server) this.server = this.lastServer
            this.servers = await proto.getCompanies()
        } catch (e) {
            console.error(e);
        }

    }
}
