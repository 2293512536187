//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {GET_IS_ELECTRON, GET_CLIENT_VERSION, GET_NEW_CLIENT_INFO, GET_CLIENT_CHANGES, GET_CLIENT_UPDATE_STATUS,
    GET_CLIENT_DOWNLOAD_SIZE, GET_CLIENT_DOWNLOAD_CURRENT_SIZE} from "../../../store/gettersTypes";
import {ACT_GET_NEW_CLIENT_INFO, ACT_UPDATE_CLIENT} from "../../../store/actionsTypes";
import {CLIENTDATA} from "../../../store/modulesNames";
import MainContentContainer from "./MainContentContainer.vue";
import ListItem from "../../custom/ListItem.vue";
import {UPDATE_STATUSES, UPDATE_ERRORS} from '../../../store/modules/clientdata'
import { i18n } from '../../../../ext/i18n'
import prettyBytes from 'pretty-bytes'
import ModalClientChanges from '../../modal/ModalClientChanges.vue'

export default {
    name: "MainContentAbout",
    components: {ListItem, MainContentContainer},
    data() {
        return {
            this_year: (new Date()).getFullYear(),
            changes: []
        }
    },
    computed: {
        logoImg() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme ? 'img/logo_dark.png' : 'img/logo_light.png'
        },
        updating() {
            return [UPDATE_STATUSES.DOWNLOADING, UPDATE_STATUSES.UPDATING, UPDATE_STATUSES.PENDING_INFO].indexOf(this.updateStatus) !== -1
        },
        versionIconClasses() {
            let classes;
            switch (this.updateStatus) {
                case UPDATE_STATUSES.HAVE_NEW_VERSION:
                case UPDATE_STATUSES.DOWNLOADING:
                case UPDATE_STATUSES.UPDATING:
                    classes = ['fas', 'fa-check-circle', 'hidden'];
                    break;
                case UPDATE_STATUSES.PENDING_INFO:
                    classes = ['fal', 'fa-spinner-third', 'fa-spin'];
                    break;
                case UPDATE_STATUSES.UP_TO_DATA:
                default:
                    classes = ['fas', 'fa-check-circle'];
            }
            return classes;
        },
        versionHeaderText() {
            let text;
            switch (this.updateStatus) {
                case UPDATE_STATUSES.HAVE_NEW_VERSION:
                case UPDATE_STATUSES.DOWNLOADING:
                case UPDATE_STATUSES.UPDATING:
                    text = i18n.messages[i18n.locale].mainPage['current-version']
                    break;
                case UPDATE_STATUSES.PENDING_INFO:
                    text = i18n.messages[i18n.locale].mainPage['check-for-ups']
                    break;
                case UPDATE_STATUSES.UP_TO_DATA:
                default:
                    text = i18n.messages[i18n.locale].mainPage['last-version']
            }
            return text;
        },
        buttonText() {
            let text;
            switch (this.updateStatus) {
                case UPDATE_STATUSES.DOWNLOADING:
                    text = i18n.messages[i18n.locale].loading
                    break;
                case UPDATE_STATUSES.UPDATING:
                    text = i18n.messages[i18n.locale].mainPage.installing
                    break;
                case UPDATE_STATUSES.UP_TO_DATA:
                default:
                    text = i18n.messages[i18n.locale].mainPage['download-install']
            }
            return text;
        },
        buttonFooterText() {
            let text;
            switch (this.updateStatus) {
                case UPDATE_STATUSES.DOWNLOADING:
                    text = i18n.messages[i18n.locale].mainPage.recieved + prettyBytes(+this.clientDownloadCurrentSize, { locale: this.$t('pretty-bytes-locale') }) + i18n.messages[i18n.locale].mainPage.of + prettyBytes(+this.clientDownloadSize, { locale: this.$t('pretty-bytes-locale') })
                    break;
                case UPDATE_STATUSES.UPDATING:
                    text = i18n.messages[i18n.locale].mainPage['install-in-progress']
                    break;
            }
            return text;
        },
        lastVersionChange () {
            return this.changes[0] && this.changes[0]['changelog'] || ''
        },
        ...mapGetters(CLIENTDATA, {
            isElectron: GET_IS_ELECTRON,
            version: GET_CLIENT_VERSION,
            newClientInfo: GET_NEW_CLIENT_INFO,
            changes: GET_CLIENT_CHANGES,
            updateStatus: GET_CLIENT_UPDATE_STATUS,
            clientDownloadSize: GET_CLIENT_DOWNLOAD_SIZE,
            clientDownloadCurrentSize: GET_CLIENT_DOWNLOAD_CURRENT_SIZE
        })
    },
    methods: {
        showAlert(text) {
            this.modalOpen({
                name: 'alert',
                props: {
                    title: this.$t('errors.error'),
                    text: text
                }
            })
        },
        openChanges() {
            this.modalOpen({
                component: ModalClientChanges,
                props: { changes: this.changes }
            })
        },
      ...mapActions(CLIENTDATA, {
          [ACT_GET_NEW_CLIENT_INFO]: ACT_GET_NEW_CLIENT_INFO,
          'updateClient': ACT_UPDATE_CLIENT}
          )
    },
    mounted: async function() {
        await this[ACT_GET_NEW_CLIENT_INFO](false);
    }
}
